import React from 'react'

const LegacyLogo = () => (
<svg>
  <g
     id="layer1"
     transform="translate(0,-972.3622)">
    <g
       id="text2985"
       transform="matrix(1.8217394,0,0,1.8217394,-130.38877,475.18359)">
      <path
         d="m 91.388574,280.78504 -3.52,0 0,10.48 -0.08,0.28 c -0.84,-1.2 -2.440004,-3.56 -6.24,-3.56 -5.559995,0 -8.72,4.56 -8.72,10.44 0,4.99999 2.080007,11.68 9.2,11.68 2.039998,0 4.440001,-0.64001 5.96,-3.48 l 0.08,0 0,2.88 3.32,0 0,-28.72 m -14.92,18.2 c 0,-2.68 0.280005,-7.84 5.72,-7.84 5.079995,0 5.64,5.48 5.64,8.88 0,5.55999 -3.480003,7.04 -5.72,7.04 -3.839997,0 -5.64,-3.48001 -5.64,-8.08"
         id="path2990" />
      <path
         d="m 113.3342,295.26504 c 0,-5.96 -4.08,-7.28 -7.32,-7.28 -3.36,0 -5.12,2.08 -6.120001,3.4 l -0.08,0 0,-10.6 -3.52,0 0,28.72 3.52,0 0,-11.4 c 0,-5.28 2.960001,-6.96 5.840001,-6.96 3.36,0 4.16,1.84 4.16,4.68 l 0,13.68 3.52,0 0,-14.24"
         id="path2992" />
    </g>
  </g>
</svg>

)

const Logo = () => (
<svg id="logo" data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.97 386">
  <title>Dhamen.photography</title>
  <path d="M121.25,111c-.21,7.6-5.11,11.34-9.18,15.56-4.89,5.05-5.27,6.63-1.77,12.58,3,5,6.11,9.94,8.83,14.35,3.52-4.16,7-8.3,10.53-12.46,4.71-5.61,5-8,1.22-14.35C127.92,121.67,124.79,116.76,121.25,111Z" transform="translate(-88.03 -111)"/>
  <path d="M213.43,134.58c-20.78-4.16-42.46,4.8-53.11,23.1-4.66,8-8.9,16.61-11.37,25.5-6.29,22.59-11.66,45.45-17,68.28-2.77,11.79-5.06,13.92-16.5,9.7-6-2.22-11.3-6.75-16.63-10.61-1.49-1.08-2-3.5-3-5.31l-2.54,1.69c-1.77,19.34-3.63,38.66-5.21,58-.2,2.52,1,5.19,1.64,7.74q1.35,5,2.75,9.91c14.11,26.29,22.46,58.92,22.46,94.26,0,29.27-5.74,56.66-15.73,80.15,14.29-28.32,16.08-59.54,18.26-90.59,1.7-24.17,4.2-48.28,6.12-72.44.46-5.82,3.13-8.28,8.62-10.39a85.67,85.67,0,0,0,22.32-12.2c26.29-20.86,46-46.15,54.8-79.57,7.48-28.45,9.66-57.31,11-86.4C220.46,140.34,219.82,135.86,213.43,134.58Zm-93.84,209s-1.35,16.86-2,16.86c-.38-5.76-4.26-28.38-4.63-34.13h7.66C120.26,332.1,119.59,343.61,119.59,343.61Zm21.35-84.33c3.64-38.59,29-63.61,61.7-60.3C186.54,224.1,168.57,246.07,140.94,259.28Z" transform="translate(-88.03 -111)"/>
  <g>
    <path d="M149.26,345.73v47.8a5.56,5.56,0,0,1-5.56,5.55h-5.55V340.17h5.55A5.56,5.56,0,0,1,149.26,345.73Zm-2.35,0a3.21,3.21,0,0,0-3.21-3.21h-3.21v54.22h3.21a3.21,3.21,0,0,0,3.21-3.21Z" transform="translate(-88.03 -111)"/>
    <path d="M180,340.17h2.34v58.91H180V370.8h-6.42v28.28h-2.35V340.17h2.35v28.28H180Z" transform="translate(-88.03 -111)"/>
    <path d="M215.39,345.73v53.35H213V370.8h-6.42v28.28h-2.34V345.73a5.56,5.56,0,1,1,11.11,0Zm-8.77,22.72H213V345.73a3.21,3.21,0,1,0-6.42,0Z" transform="translate(-88.03 -111)"/>
    <path d="M248.45,340.17v58.91H246.1V355.73l-2,13.28h-2.37l-2-13.28v43.35h-2.35V340.17h2.36l3.2,21,3.19-21Z" transform="translate(-88.03 -111)"/>
    <path d="M270.4,340.17h11.12v2.35h-8.77v25.93h8.77v2.35h-8.77v25.94h8.77v2.34H270.4Z" transform="translate(-88.03 -111)"/>
    <path d="M312.23,340.17h2.35v58.91h-2.39L305.82,356v43.13h-2.35V340.17h2.39l6.37,43.14Z" transform="translate(-88.03 -111)"/>
    <path d="M149.26,419.34v19.52a5.56,5.56,0,0,1-5.56,5.56h-3.21V472.7h-2.34V413.79h5.55A5.56,5.56,0,0,1,149.26,419.34Zm-2.35,0a3.21,3.21,0,0,0-3.21-3.2h-3.21v25.93h3.21a3.21,3.21,0,0,0,3.21-3.21Z" transform="translate(-88.03 -111)"/>
    <path d="M163.82,413.79h2.35V472.7h-2.35V444.42h-6.41V472.7h-2.35V413.79h2.35v28.28h6.41Z" transform="translate(-88.03 -111)"/>
    <path d="M183.08,419.34v47.81a5.56,5.56,0,0,1-11.11,0V419.34a5.56,5.56,0,0,1,11.11,0Zm-2.35,0a3.21,3.21,0,0,0-6.41,0v47.81a3.21,3.21,0,1,0,6.41,0Z" transform="translate(-88.03 -111)"/>
    <path d="M198.31,413.79v2.35h-4.38V472.7h-2.35V416.14H187.2v-2.35Z" transform="translate(-88.03 -111)"/>
    <path d="M213.54,419.34v47.81a5.56,5.56,0,0,1-11.11,0V419.34a5.56,5.56,0,0,1,11.11,0Zm-2.35,0a3.21,3.21,0,0,0-6.42,0v47.81a3.21,3.21,0,0,0,6.42,0Z" transform="translate(-88.03 -111)"/>
    <path d="M230.45,419.34v1.18H228.1v-1.18a3.21,3.21,0,0,0-6.41,0v47.81a3.21,3.21,0,1,0,6.41,0V444.42h-4.38v-2.35h6.73v25.08a5.56,5.56,0,0,1-11.11,0V419.34a5.56,5.56,0,0,1,11.11,0Z" transform="translate(-88.03 -111)"/>
    <path d="M247.36,419.34v19.52a5.55,5.55,0,0,1-2.15,4.39,5.52,5.52,0,0,1,2.15,4.38V472.7H245V447.63a3.21,3.21,0,0,0-3.21-3.21h-3.21V472.7h-2.34V413.79h5.55A5.57,5.57,0,0,1,247.36,419.34Zm-5.56,22.73a3.21,3.21,0,0,0,3.21-3.21V419.34a3.21,3.21,0,0,0-3.21-3.2h-3.21v25.93h3.21Z" transform="translate(-88.03 -111)"/>
    <path d="M264.27,419.34V472.7h-2.35V444.42H255.5V472.7h-2.34V419.34a5.56,5.56,0,0,1,11.11,0Zm-8.77,22.73h6.42V419.34a3.21,3.21,0,0,0-6.42,0Z" transform="translate(-88.03 -111)"/>
    <path d="M281.18,419.34v19.52a5.56,5.56,0,0,1-5.56,5.56h-3.2V472.7h-2.35V413.79h5.55A5.56,5.56,0,0,1,281.18,419.34Zm-2.35,0a3.21,3.21,0,0,0-3.21-3.2h-3.2v25.93h3.2a3.21,3.21,0,0,0,3.21-3.21Z" transform="translate(-88.03 -111)"/>
    <path d="M295.74,413.79h2.35V472.7h-2.35V444.42h-6.41V472.7H287V413.79h2.35v28.28h6.41Z" transform="translate(-88.03 -111)"/>
    <path d="M312.65,413.79H315v53.36a5.56,5.56,0,0,1-11.11,0V466h2.35v1.18a3.21,3.21,0,1,0,6.41,0V443.4a5.55,5.55,0,0,1-8.76-4.54V413.79h2.35v25.07a3.21,3.21,0,1,0,6.41,0Z" transform="translate(-88.03 -111)"/>
  </g>
</svg>


)

export default Logo
